html, body, div#root {
  padding: 0;
  margin: 0;
  width: 100%;
  max-width: 100vw;
  min-height: -webkit-fill-available;
  position: relative;
  height: 100%;
}

* {
  box-sizing: border-box;
}

:root {
  --card-size: 200px;
  --card-font: 16px;
}

@media screen and (max-width: 600px) {
  :root {
    --card-size: 150px;
    --card-font: 14px;
  }
}